inm-layout:not(:empty) + inm-splash .splash {
  opacity: 0;
  visibility: hidden;
}

/* layout component not loaded when User Not Assigned application*/
inm-error-page:not(:empty) + inm-splash .splash {
  opacity: 0;
  visibility: hidden;
}
