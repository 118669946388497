/* stylelint-disable selector-no-qualifying-type */

@import '~@inmarsat-itcloudservices/ui/assets/styles/variables';

// bootstrap css files
@import '~@inmarsat-itcloudservices/ui/assets/styles/bootstraper';

// setup files
@import '~@inmarsat-itcloudservices/ui/assets/styles/fonts';
@import '~@inmarsat-itcloudservices/ui/assets/styles/mixins';
@import '~@inmarsat-itcloudservices/ui/assets/styles/forms';
@import '~@inmarsat-itcloudservices/ui/assets/styles/checkbox';
@import '~@inmarsat-itcloudservices/ui/assets/styles/radio';
// general site css
@import '~@inmarsat-itcloudservices/ui/assets/styles/layout';
@import '~@inmarsat-itcloudservices/ui/assets/styles/typography';
// bootstrap setup + utils
// @import 'bootstrap';
// feature specific styles
@import '~@inmarsat-itcloudservices/ui/assets/styles/overflow-tooltip';
// animation css before loading the Okta login page
@import 'assets/styles/splash.css';
// metadata panel css
@import 'metadata';
// form css
@import 'inline-edit-form';

// bootstrap timepicker inputs https://valor-software.com/ngx-bootstrap/#/timepicker#config-defaults
.inm-layout {
  .bs-timepicker-field {
    @include input-field;
    width: 55px;
  }

  .has-error {
    .bs-timepicker-field {
      border-color: $error;
    }
  }
}

html,
body {
  background-color: $white-grey !important;
  height: 100%;

  dl,
  dt,
  dd {
    margin: 0;
  }

  a,
  button {
    cursor: pointer;
    outline: none !important;
  }
}

mark.inm-highlight {
  background-color: $yellow;
  padding: 0;
}

// overriding pagination
ul.pagination {
  display: inline-block;
  font-size: 0.875rem;
  list-style: none;
  margin-bottom: 0;
  padding: 0 0.5rem;
  text-align: center;

  .page-item {
    float: left;
    margin: 0 0.5rem;
  }

  .nav-link,
  .page-link {
    color: $dark-blue;
    display: inline;
    font-weight: 300;
    padding: 0.5rem;
    text-decoration: none;
  }

  .page-link {
    color: $dark-blue;
    display: inline-block;
    font-weight: 400;
  }

  .active {
    display: inline-block;

    .page-link {
      color: $mid-blue;
      display: inline-block;
      font-weight: 600;
    }
  }

  .pagination-prev {
    .page-link {
      font-weight: 600;

      &::before {
        @include chevron($mid-blue, 'backward');
        border-width: 1px;
        height: 6px;
        margin-bottom: 2px;
        margin-left: 0;
        margin-right: 1rem;
        width: 6px;
      }
    }
  }

  .pagination-next {
    .page-link {
      font-weight: 600;

      &::after {
        @include chevron($mid-blue, 'forward');
        border-width: 1px;
        height: 6px;
        margin-bottom: 2px;
        width: 6px;
      }
    }
  }

  .disabled {
    color: $light-grey;

    .page-link {
      color: $light-grey;

      &:hover {
        cursor: default;
        text-decoration: none;
      }
    }
  }
}

.inm-list-filter {
  margin-bottom: 1.5rem;
}

.inm-back-link {
  align-items: center;
  display: inline-flex;
  font-size: 14px;

  &::before {
    @include chevron($mid-blue, 'backward', 'left');
    margin-right: 0.5rem;
  }
}

.inm-pagination-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.inm-input-search-wrap {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.btn-circle {
  background-color: $mid-blue;
  border: 0;
  border-radius: 15px;
  color: $white;
  font-size: 0.875rem;
  height: 30px;
  line-height: 0.6;
  padding: 6px 0;
  text-align: center;
  vertical-align: middle;
  width: 30px;
}

.inm-textinput {
  &-row {
    flex-direction: row;
  }
}

input[type='text']:disabled {
  background-color: $disabled-light-grey;
  cursor: not-allowed;
}

.crs-help {
  cursor: help;
}

.crs-pointer {
  cursor: pointer;
}

.badge-primary {
  background-color: $light-blue;
}

.border-primary {
  border-color: $light-blue !important;
}

.text-primary {
  color: $light-blue !important;
}

.font-large {
  font-size: 1.5rem;
}

.inm-icon {
  width: 16px;
}

@mixin status-icon {
  border-radius: 100%;
  display: inline-block;
  height: 8px;
  margin-right: 0.5rem;
  width: 8px;
}

.status-icon-green {
  @include status-icon;
  background-color: $status-green;
}

.status-icon-orange {
  @include status-icon;
  background-color: $status-orange;
}

.status-icon-grey {
  @include status-icon;
  background-color: $status-grey;
}

.cdk-overlay-container {
  // Raise it above the modal background:
  z-index: 2000;
}

.message {
  line-height: 1rem;
  white-space: pre-line;
}

.tab-button {
  cursor: pointer;

  &.active {
    cursor: default;
  }
}

.h-100 {
  height: 100%;
}

.text-underline {
  text-decoration: underline;
}

.bg-primary-dark,
.badge-primary-dark {
  background-color: $dark-blue;
}

.bg-highlight {
  background-color: rgba(0, 185, 255, 0.1);
}

.bg-highlight-account {
  background-color: rgba(0, 43, 82, 0.1);
}

.bg-highlight-account-children {
  background-color: rgba(0, 185, 255, 0.1);
}

.bg-highlight-site {
  background-color: rgba(29, 161, 25, 0.1);
}
