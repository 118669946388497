@import '~@inmarsat-itcloudservices/ui/assets/styles/variables';

$highlight-yellow: #fffaa0;
$lighter-blue: #e5f8ff;

.inline-edit-form {
  .inm-textinput__input {
    background-color: transparentize($highlight-yellow, 0.25);
    height: 30px !important;

    &:focus {
      background-color: $white;
      outline: $highlight-yellow auto 1px;
    }
  }

  inm-typeahead {
    background-color: $lighter-blue !important;
    height: 30px !important;
  }

  inm-tree-select {
    background-color: $lighter-blue !important;
    height: 30px !important;
  }

  inm-search-box {
    background-color: $lighter-blue !important;
    height: 30px !important;

    input {
      background-color: $lighter-blue !important;
    }
  }
}
